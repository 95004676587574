// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const playersPerTeam = [
    { label: '5 por equipo', year: 1994 },
    { label: '6 por equipo', year: 1972 },
    { label: '7 por equipo', year: 1972 },
    { label: '8 por equipo', year: 1972 },
    { label: '9 por equipo', year: 1972 },
    { label: '10 por equipo', year: 1972 },
    { label: '11 por equipo', year: 1972 },
  ];
  
  export default playersPerTeam;
  